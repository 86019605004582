































































































































import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
  useRouter
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfBanner, SfBannerGrid } from '@storefront-ui/vue';
import {
  CmsPage,
  RangeSliderCategory,
  Settings,
  DynamicPage,
  DynamicComponent
} from '~/modules/GraphQL/types';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent, wntrUseUser, useUiState } from '~/composables';
// import { AisSearchBox } from 'vue-instantsearch';

export default defineComponent({
    name: 'Homepage',
    // scrollToTop: true,
    components: {
        LazyHydrate,
        SfBanner,
        SfBannerGrid,
        LocalBusiness: () => import(/* webpackPrefetch: true */ '~/components/Schema/LocalBusiness.vue'),
        SearchSchema: () => import(/* webpackPrefetch: true */ '~/components/Schema/SearchSchema.vue'),
        CallToAction: () => import(/* webpackPrefetch: true */ '~/components/CallToAction.vue'),
        InstagramFeed: () => import(/* webpackPrefetch: true */ '~/components/InstagramFeed.vue'),
        MobileStoreBanner: () => import(/* webpackPrefetch: true */ '~/components/MobileStoreBanner.vue'),
        NewProducts: () => import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
        // AisSearchBox
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup(props, context) {

        // console.log(isAuthenticated.value);
        // const { addTags } = useCache();
        // const { loadPage } = useContent();
        const { loadRangeSlider, loadSettings, loadDynamicPage, loadDynamicComponents } = useContent();
        // const { app } = useContext();
        // const year = new Date().getFullYear();
        // const { isDesktop } = app.$device;

        // const page = ref<CmsPage | null>(null);
        // const rangeSlider = ref<RangeSliderCategory[] | null>(null);
        // const settings = ref<Settings | null>(null);
        
        // const hero = ref({
        //   title: app.i18n.t('Colorful summer dresses are already in store'),
        //   subtitle: app.i18n.t('SUMMER COLLECTION {year}', { year }),
        //   buttonText: app.i18n.t('Learn more'),
        //   imageSrc: '/homepage/bannerB.webp',
        //   imageWidth: isDesktop ? 1240 : 328,
        //   imageHeight: isDesktop ? 400 : 224,
        //   imageConfig: {
        //     fit: 'cover',
        //     format: 'webp',
        //   },
        //   link: '/women.html',
        // });

        // const callToAction = ref({
        //   title: app.i18n.t('Subscribe to Newsletters'),
        //   description: app.i18n.t('Be aware of upcoming sales and events. Receive gifts and special offers!'),
        //   buttonText: app.i18n.t('Subscribe'),
        //   imageSrc: '/homepage/newsletter.webp',
        //   imageWidth: isDesktop ? 1240 : 400,
        //   imageHeight: isDesktop ? 202 : 200,
        //   imageConfig: {
        //     fit: 'cover',
        //     format: 'webp',
        //   },
        // });

        // useFetch(async () => {
        //   page.value = await loadPage({ identifier: 'home' });
        // });
        
        const router = useRouter();
        const route = context.root.$route

        // const dynamicPage = ref<DynamicPage | null>(null)
        // const dynamicComponents = ref<DynamicComponent[] | null>(null)
        const slug = '/'//route.path.replace(/^\/+/g, '');

        let dynamicPageRef = loadDynamicPage({ identifier: slug })

        const components_array = [
            'home-slick-carousel',
            'home-benefits-bar',
            'home-zoom-cards-product-categories',
            'home-icon-bar',
            'home-cards',
            'generic-range-slider',
            'home-hero-banner',
            'home-zoom-cards-kitchen-styles',
            'generic-customer-stories',
            'generic-full-image-card-banner',
            'home-video-split'
        ]
        const components_string = components_array.join(',')
        let dynamicComponentsRef = loadDynamicComponents({ identifier: components_string })

        // useFetch(async () => {
          var rangeSliderRef = loadRangeSlider({ identifier: 'home' });
          var settingsRef = loadSettings({ identifier: 'home' });
          // console.log('*** Settings from WinterCMS: ***');
          // console.log(settings);
        // });


        // onMounted(() => {
        //   addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
        // });

        // @ts-ignore
        // return {
        //   callToAction,
        //   hero,
        //   page,
        // };
        // @ts-ignore

        const { activateUser } = wntrUseUser();
        const { toggleLoginModal } = useUiState();

        return {
            activateUser,
            rangeSliderRef,
            settingsRef,
            dynamicPageRef,
            dynamicComponentsRef,
            toggleLoginModal
        }
    },
    async fetch() {
        this.dynamicPage = await this.dynamicPageRef
        this.dynamicComponents = await this.dynamicComponentsRef

        this.rangeSlider = await this.rangeSliderRef
        this.settings = await this.settingsRef

        this.processDynamicContent()
    },
    head() {

        var meta_image = ''
        if ( this.dynamicPage.meta_image ) {
            meta_image = `${this.image_prefix}c_fill,w_1200,h_630/f_jpg,q_70/${this.dynamicPage.meta_image}`
        }

        return {
            title: this.dynamicPage.meta_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.dynamicPage.meta_description
                },
                {
                    hid: 'og:description',
                    name: 'og:description',
                    content: this.dynamicPage.meta_description
                },
                {
                  hid: 'og:title',
                  name: 'og:title',
                  content: this.dynamicPage.meta_title
                },
                // {
                //   hid: 'og:site_name',
                //   name: 'og:site_name',
                //   content: this.dynamicPage.meta_title
                // },
                {
                  hid: 'og:url',
                  name: 'og:url',
                  content: this.dynamicPage.canonical_url
                },
                {
                  hid: 'og:image',
                  name: 'og:image',
                  content: meta_image
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: this.dynamicPage.canonical_url
                }
            ]
        }
    },
    mounted() {

      if (this.$route.name == 'activation') this.activateUserAction();
    },
    data() {
        return {
            dynamicPage: {},
            dynamicComponents: [],
            dynamicContent: {
              'home-slick-carousel': null,
              'home-benefits-bar': null,
              'home-zoom-cards-product-categories': null,
              'home-icon-bar': null,
              'home-cards': null,
              'generic-range-slider': null,
              'home-hero-banner': null,
              'home-zoom-cards-kitchen-styles': null,
              'generic-customer-stories': null,
              'generic-full-image-card-banner': null,
              'home-video-split': null
            },

            rangeSlider: null,
            settings: null

        }
    },
    methods: {
        async activateUserAction() {
            
            const res = await this.activateUser({
                customQuery: {
                    code: this.$route.query.code
                }
            });

            localStorage.setItem('activate_user', res)
            this.toggleLoginModal();
        },
        processDynamicContent() {
            if (this.dynamicComponents && this.dynamicComponents.length) {
                this.dynamicComponents.forEach(element => {
                    this.dynamicContent[element.id] = element.data
                });
            }
        }
    }
});
