import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0354922e&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=0354922e&prod&lang=scss&"
import style1 from "./default.vue?vue&type=style&index=1&id=0354922e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notification: require('/var/www/components/Notification.vue').default,OnlineCheck: require('/var/www/components/OnlineCheck.vue').default,AppHeader: require('/var/www/components/AppHeader.vue').default,TopBar: require('/var/www/components/TopBar/TopBar.vue').default,AppFooter: require('/var/www/components/AppFooter.vue').default})
