import { render, staticRenderFns } from "./FullImageCardBanner.vue?vue&type=template&id=43d4b10e&scoped=true&"
import script from "./FullImageCardBanner.vue?vue&type=script&lang=ts&"
export * from "./FullImageCardBanner.vue?vue&type=script&lang=ts&"
import style0 from "./FullImageCardBanner.vue?vue&type=style&index=0&id=43d4b10e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d4b10e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleDescription: require('/var/www/components/TitleDescription.vue').default,ConsultationForm: require('/var/www/components/ConsultationForm.vue').default})
