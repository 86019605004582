


















































export default {
    name: 'Cards',
    components: {
        
    },
    inject: [
        'image_format'
    ],
    props: {
        data: {
            type: Object,
            default () {
                return {
                    title: String,
                    description: String,
                    aspect_ratio: String,
                    items: {
                        type: Array,
                        default() {
                            return {
                                title: String,
                                description: String,
                                cutout: String,
                                image: {
                                    type: Object,
                                    default() {
                                        return {
                                            src: String,
                                            width: Number,
                                            height: Number,
                                            alt: String
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    methods: {
        getApectRatioHeight() {
            var aspect_ratio = this.data.aspect_ratio ? this.data.aspect_ratio : 'portrait';
            var heights = {
                landscape: 318/469,
                square: 469/469,
                portrait: 501/469
            }
            return heights[aspect_ratio]
        }
    }
}
