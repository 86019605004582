var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trust-pilot-featured row"},[(_vm.content)?[_c('VueSlickCarousel',_vm._b({staticClass:"padded-horizontal px-0"},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.content),function(ref,index){
var image = ref.image;
var cutout = ref.cutout;
var title = ref.title;
var description = ref.description;
var button = ref.button;
var url = ref.url;
return _c('div',{key:(title + "-" + index),staticClass:"col-12 col-md-4 pb-5 md-pb-0"},[_c('div',{staticClass:"card shadow light radius h-100"},[_c('div',{staticClass:"card-img",class:{'lower-cutout': cutout === '“'},attrs:{"data-after":cutout}},[_c('img',{staticClass:"card-img-top",attrs:{"sizes":"(min-width: 1200px) calc((100vw - 128px) / 3), (min-width: 992px) calc((100vw - 64px) / 3), (min-width: 576px) calc(100vw - 112px), calc(100vw - 80px)","srcset":("\n                                " + _vm.image_prefix + "c_fill,w_320,h_240/" + (_vm.image_format.name) + ",q_70/" + (image.src) + " 320w,\n                                " + _vm.image_prefix + "c_fill,w_450,h_338/" + (_vm.image_format.name) + ",q_70/" + (image.src) + " 450w,\n                                " + _vm.image_prefix + "c_fill,w_640,h_481/" + (_vm.image_format.name) + ",q_70/" + (image.src) + " 640w,\n                                " + _vm.image_prefix + "c_fill,w_768,h_577/" + (_vm.image_format.name) + ",q_70/" + (image.src) + " 768w,\n                                " + _vm.image_prefix + "c_fill,w_896,h_577/" + (_vm.image_format.name) + ",q_70/" + (image.src) + " 896w,\n                                " + _vm.image_prefix + "c_fill,w_1024,h_769/" + (_vm.image_format.name) + ",q_70/" + (image.src) + " 1024w\n                            "),"src":(_vm.image_prefix + "c_fill,w_470,h_353/" + (_vm.image_format.name) + ",q_70/" + (image.src)),"alt":image.alt,"loading":"lazy","width":"450","height":"338"}})]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(title))]),_vm._v(" "),_c('p',{staticClass:"card-text font-13"},[_vm._v(_vm._s(description))])]),_vm._v(" "),_c('div',{staticClass:"card-footer"},[_c('nuxt-link',{staticClass:"font-13 text-decoration-underline semi-bold hover-larks-stone",attrs:{"to":url}},[_vm._v(_vm._s(button))])],1)])])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }