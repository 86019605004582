import { render, staticRenderFns } from "./checkout.vue?vue&type=template&id=b9438436&"
import script from "./checkout.vue?vue&type=script&lang=ts&"
export * from "./checkout.vue?vue&type=script&lang=ts&"
import style1 from "./checkout.vue?vue&type=style&index=1&id=b9438436&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notification: require('/var/www/components/Notification.vue').default,OnlineCheck: require('/var/www/components/OnlineCheck.vue').default,AppHeader: require('/var/www/components/AppHeader.vue').default,CheckoutHeader: require('/var/www/components/CheckoutHeader.vue').default,CheckoutFooter: require('/var/www/components/CheckoutFooter.vue').default})
