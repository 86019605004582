














































































import {
    SfButton
} from '@storefront-ui/vue';

import {
    // computed,
    // ref,
    defineComponent,
    // useRouter,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'HeroBanner',
    components: {
        SfButton
    },
    // setup() {
    //     return {
            
    //     }
    // },
    inject: [
        'image_format'
    ],
    props: {
        content: {
            type: Object,
            default() {
                return {
                    title_1: String,
                    title_2: String,
                    title_3: String,
                    cta: String,
                    url: Object,
                    mobileImage: {
                        type: Object,
                        default() {
                            return {
                                src: String,
                                width: Number,
                                height: Number,
                                alt: String
                            }
                        }
                    },
                    desktopImage: {
                        type: Object,
                        default() {
                            return {
                                src: String,
                                width: Number,
                                height: Number,
                                alt: String
                            }
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    }
});

