






































	import LazyHydrate from 'vue-lazy-hydration';
	import { useRoute, defineComponent } from '@nuxtjs/composition-api';
	import { useUiState } from '~/composables';
	import AppHeader from '~/components/AppHeader.vue';
	import IconSprite from '~/components/General/IconSprite.vue';
	import TopBar from '~/components/TopBar/TopBar.vue';

	import "~/assets/styles.scss";
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	// import 'slick-carousel/slick/slick.css';
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
	// import 'bootstrap/dist/css/bootstrap.css'
	import 'bootstrap-vue/dist/bootstrap-vue.min.css'
	// import 'bootstrap-icons/font/bootstrap-icons.css'
	// import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from 'bootstrap-vue'
	
	// import { BootstrapVue } from 'bootstrap-vue'
	import Vue from 'vue'
	// Vue.use(BootstrapVue)

	import { CardPlugin, ModalPlugin, CollapsePlugin, ButtonPlugin, TabsPlugin, NavbarPlugin,FormDatepickerPlugin } from 'bootstrap-vue'
    Vue.use(CardPlugin)
	Vue.use(ModalPlugin)
	Vue.use(CollapsePlugin)
	Vue.use(ButtonPlugin)
	Vue.use(TabsPlugin)
	Vue.use(NavbarPlugin)
	Vue.use(FormDatepickerPlugin)

	// Vue.use(BootstrapVueIcons)

	/* import the fontawesome core */
	// import { library } from '@fortawesome/fontawesome-svg-core'

	/* import font awesome icon component */
	// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

	export default defineComponent({
		name: 'DefaultLayout',
		// transition: {
        //     name: 'fade',
        //     mode: 'out-in'
        // },
		components: {
			LazyHydrate,
			AppHeader,
			// BottomNavigation,
			IconSprite,
			TopBar,
			AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
			CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
			WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
			LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
			Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
		},
		// mounted() {
		// 	console.log('mounted')
		// 	this.detectImageFormat()
		// },
		setup() {
			const route = useRoute();
			const {
				isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal,
			} = useUiState();

			return {
				isCartSidebarOpen,
				isWishlistSidebarOpen,
				isLoginModalOpen,
				toggleLoginModal,
				route
			};
		},
		created() {
			// this.detectImageFormat()
		},
		mounted() {
			if ( process.client ) {
				window.addEventListener("scroll", this.handleScroll);
			}
			this.detectImageFormat()
		},
		head: {
			link: [
				{ rel: 'stylesheet', href: '/_nuxt/fonts.css' },
				{ rel: 'preload', href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap', as: 'style' },
				{ rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap' }
			]
		},
		computed: {
			showFullNavOutput: function() {
				return this.showFullNav.join(' ')
			}
		},
		provide() {
			return {
				image_format: this.image_format
			}
		},
		methods: {
			detectImageFormat: function () {
				if(process.client) {
					for (let type of [ /*'image/png', 'image/jpeg', 'image/webp', */ 'image/avif']) {
						this.supportsImgType(type).then((supported) => {
							if (type === 'image/avif' && !supported) {
								this.image_format.name = 'f_auto'
							}
						})
					}
				}
			},
			async supportsImgType(type) {
				let img = document.createElement('img');
				document.createElement('picture').append(
					Object.assign(document.createElement('source'), {
						srcset: 'data:,x',
						type
					}),
					img
				);
				// Wait a single microtick just for the `img.currentSrc` to get populated.
				await 0;
				// At this point `img.currentSrc` will contain "data:,x" if format is supported and "" otherwise.
				return !!img.currentSrc;
			},
			handleScroll: function () {
				if (process.client) {
					var currentScrollPosition = window.scrollY
					var headerHeight = this.$refs.nav.clientHeight
					var headerWidth = this.$refs.nav.clientWidth
					var previousNavClass = this.showFullNav

					// console.log(headerWidth)

					this.showFullNav = []
					if (currentScrollPosition > this.scrollPosition && currentScrollPosition > headerHeight && headerWidth >= 992) {
						this.showFullNav.push('hide')
					} else {
						this.showFullNav.push('show')
					}

					if (!(currentScrollPosition < this.scrollPosition && currentScrollPosition < headerHeight)) { // upwards within headerheight
						if (currentScrollPosition < headerHeight && currentScrollPosition > this.scrollPosition) { // downwards within headerheight
							this.showFullNav.push('relative')
						}
						if (currentScrollPosition > this.scrollPosition) { // downwards
							if (previousNavClass.includes('relative')) { // retain relative
								this.showFullNav.push('relative')	
							}
						}
					}

					this.scrollPosition = window.scrollY
				}
			},
		},
		data() {
			return {
				showFullNav: ['show'],
				scrollPosition: null,
				image_format: {
					name: 'f_avif'
				}
			}
		}
	});
