


































































import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { NuxtError } from '@nuxt/types';
import LazyHydrate from 'vue-lazy-hydration';

import { useContent } from '~/composables';
import { ref, watchEffect } from '@nuxtjs/composition-api';
// import type { DynamicComponent } from '~/modules/GraphQL/types';

export default defineComponent({
    components: {
        LazyHydrate
    },
    // setup(props, context) {
    //     const { loadDynamicComponents } = useContent()
    //     // const dynamicComponents = ref<DynamicComponent[] | null>(null)

    //     const components_array = [
    //         'generic-full-image-card-banner'
    //     ]
    //     const components_string = components_array.join(',')
    //     var dynamicComponentsRef = loadDynamicComponents({ identifier: components_string })

    //     return {
    //         // dynamicComponents,
    //         dynamicComponentsRef
    //     }
    // },
    // setup(props, context) {
    //     const dynamicComponentsRef = ref([]);
    //     const { loadDynamicComponents } = useContent();

    //     // Load dynamic components
    //     const components_array = ['generic-full-image-card-banner'];
    //     const components_string = components_array.join(',');

    //     // Fetch the components
    //     loadDynamicComponents({ identifier: components_string }).then(res => {
    //         dynamicComponentsRef.value = res;
    //         processDynamicContent();
    //     });

    //     const dynamicContent = ref({
    //         'generic-full-image-card-banner': null
    //     });

    //     const processDynamicContent = () => {
    //         if (dynamicComponentsRef.value && dynamicComponentsRef.value.length) {
    //             dynamicComponentsRef.value.forEach(element => {
    //                 dynamicContent.value[element.id] = element.data;
    //             });
    //         }
    //     };

    //     return {
    //         dynamicComponentsRef,
    //         dynamicContent
    //     };
    // },
    // async fetch() {
    //     this.dynamicComponents = await this.dynamicComponentsRef
    //     this.processDynamicContent()
    // },
    props: {
        error: {
            type: Object as PropType<NuxtError>,
            required: true,
        },
    },
    data() {
        return {
            cnt: null,
            asyncContent: null
        }
    },
    // data() {
    //     return {
    //         image_prefix: '',
    //         dynamicComponents: [],
    //         dynamicContent: {
    //             'generic-full-image-card-banner': null
    //         }
    //     }
    // },
    // head() {
    //     return {
    //         title: '404 Not Found',
    //         meta: [
    //             {
    //                 hid: 'description',
    //                 name: 'description',
    //                 content: '404 Not Found'
    //             },
    //             {
    //                 hid: 'robots',
    //                 name: 'robots',
    //                 content: 'noindex, nofollow'
    //             }
    //         ]
    //     }
    // },
    // created() {
    //     this.image_prefix = this.$config.baseImageUrl
    //     this.manual_fetch()
    // },
    // methods: {
    //     async manual_fetch() {
    //         this.dynamicComponents = await this.dynamicComponentsRef

    //         this.processDynamicContent()
    //     },
    //     processDynamicContent() {
    //         if (this.dynamicComponents && this.dynamicComponents.length) {
    //             this.dynamicComponents.forEach(element => {
    //                 this.dynamicContent[element.id] = element.data
    //             });
    //         }
    //     }
    // },
    // watch: {
    //     error(err) {
    //         if (err.statusCode) 
    //     }
    // },
    computed: {
        showErrorDetails(): boolean {
            return process.env.NODE_ENV !== 'production' || process.env.FORCE_SHOW_ERROR_DETAILS === 'true';
        },
    },
    mounted() {
        // We want to know how we got here, so log an error to the console with a backtrace
        // console.error('Error page', this.error);
        // const error = new Error('Error page');
        // console.log(error);
        // console.log('error stack');
        // console.log(error.statusCode);

        if (this.error && this.error.statusCode) {
           
            if (this.error.statusCode === 404) {

                this.$router.push({ path: '/404', replace: true });
            } else if (this.error.statusCode === 500) {
                localStorage.setItem('error', JSON.stringify({
                    status: this.error.statusCode,
                    message: this.error.message
                }));
                // this.$router.push({ path: '/error'});
                
                this.asyncContent = 
                `<div class="error-message">
                    <i class="fa-regular fa-cloud-exclamation"></i>
                    <h1 class="my-5">Oops, looks like you lost internet connection!</h1>
                    <button class="btn small primary semi-bold border-radius round font-12" onclick="location.reload()">Refresh</button>
                </div>`;
                
            } else if (this.error.message) {
                localStorage.setItem('error', JSON.stringify({
                    status: this.error.statusCode,
                    message: this.error.message
                }));
                this.asyncContent = 
                `<div class="error-message">
                    <i class="fa-regular fa-cloud-exclamation"></i>
                    <h1 class="my-5">${this.error.message}</h1>
                    <button class="btn small primary semi-bold border-radius round font-12" onclick="location.reload()">Refresh</button>
                </div>`;
            }

            if (this.asyncContent !== null) {
                document.querySelector('#layout > div').innerHTML = this.asyncContent;
            }
        }
    },
});
