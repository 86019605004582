




































	import LazyHydrate from 'vue-lazy-hydration';
	import { useRoute, defineComponent } from '@nuxtjs/composition-api';
	import { useUiState } from '~/composables';
	import IconSprite from '~/components/General/IconSprite.vue';

	import "~/assets/styles.scss";
	// import 'bootstrap/dist/css/bootstrap.css'
	import 'bootstrap-vue/dist/bootstrap-vue.min.css'
	// import 'bootstrap-icons/font/bootstrap-icons.css'
	// import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from 'bootstrap-vue'
	import { BootstrapVue } from 'bootstrap-vue'
	import Vue from 'vue'
	Vue.use(BootstrapVue)
	// Vue.use(BootstrapVueIcons)

	/* import the fontawesome core */
	// import { library } from '@fortawesome/fontawesome-svg-core'

	/* import font awesome icon component */
	// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

	export default defineComponent({
		name: 'CheckoutLayout',
		// transition: {
        //     name: 'fade',
        //     mode: 'out-in'
        // },
		components: {
			LazyHydrate,
			IconSprite,
			CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
			WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
			LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
			Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
		},
		setup() {
			const route = useRoute();
			const {
				isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal,
			} = useUiState();

			return {
				isCartSidebarOpen,
				isWishlistSidebarOpen,
				isLoginModalOpen,
				toggleLoginModal,
				route,
			};
		},
		created() {
			// this.detectImageFormat()
		},
		mounted() {
			if ( process.client ) {
				window.addEventListener("scroll", this.handleScrollCheckout);
			}
			this.detectImageFormat()
		},
		head: {
			link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
		},
		provide() {
			return {
				image_format: this.image_format
			}
		},
		methods: {
			detectImageFormat: function () {
				if(process.client) {
					for (let type of [ /*'image/png', 'image/jpeg', 'image/webp', */ 'image/avif']) {
						this.supportsImgType(type).then((supported) => {
							if (type === 'image/avif' && !supported) {
								this.image_format.name = 'f_auto'
							}
						})
					}
				}
			},
			async supportsImgType(type) {
				let img = document.createElement('img');
				document.createElement('picture').append(
					Object.assign(document.createElement('source'), {
						srcset: 'data:,x',
						type
					}),
					img
				);
				// Wait a single microtick just for the `img.currentSrc` to get populated.
				await 0;
				// At this point `img.currentSrc` will contain "data:,x" if format is supported and "" otherwise.
				return !!img.currentSrc;
			},
			handleScrollCheckout: function () {
				if (process.client) {
					var currentScrollPosition = window.scrollY
					if (currentScrollPosition < this.scrollPosition) {
						this.showFullNav = 'show';
					} else {
						this.showFullNav = 'hide';
					}
					this.scrollPosition = window.scrollY
				}
			},
		},
		data() {
			return {
				showFullNav: 'show',
				scrollPosition: null,
				image_format: {
					name: 'f_avif'
				}
			}
		}
	});
