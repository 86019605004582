

























































import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent ({
    name: 'ZoomCards',
    components: {
        
    },
    data() {
        return {
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    inject: [
        'image_format'
    ],
    props: {
        margin: {
            type: String,
            default: ''
        },
        padding: {
            type: String,
            default: ''
        },
        rowCols: {
            type: Object,
            default () {
                return {
                    sm: {
                        type: Number,
                        default: 2
                    },
                    lg: {
                        type: Number,
                        default: 3
                    }
                }
            }
        },
        data: {
            type: Object,
            default () {
                return {
                    title: String,
                    description: String,
                    aspect_ratio: String,
                    background: String,
                    items: {
                        type: Array,
                        default() {
                            return {
                                image: {
                                    type: Object,
                                    default() {
                                        return {
                                            src: String,
                                            width: Number,
                                            height: Number,
                                            alt: String
                                        }
                                    }
                                },
                                title: String,
                                description: String,
                                button: String,
                                url: String
                            }
                        }
                    }
                }
            }
        }
    },
    methods: {
        getApectRatioHeight() {
            var aspect_ratio = this.data.aspect_ratio ? this.data.aspect_ratio : 'portrait';
            var heights = {
                landscape: 318/469,
                square: 469/469,
                portrait: 637/469
            }
            return heights[aspect_ratio]
        }
    }

});
