import { render, staticRenderFns } from "./ZoomCards.vue?vue&type=template&id=50bece65&scoped=true&"
import script from "./ZoomCards.vue?vue&type=script&lang=ts&"
export * from "./ZoomCards.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50bece65",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleDescription: require('/var/www/components/TitleDescription.vue').default})
