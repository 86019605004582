//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    // computed,
    // ref,
    defineComponent,
    // useRouter,
    // useContext,
    onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'RangeSliderItem',
    props: ['image', 'title', 'price', 'sale_price', 'url', 'finish_count', 'finishes',  'selectedFinish', 'hoverImage', 'type'],
    inject: [
        'image_format'
    ],
    setup() {
        onMounted(() => {
            // console.log('test: ' + this.hoverImage)
        })
    },
    data: function () {
        return {
            localSelectedFinish: 0,//this.selectedFinish,
            localHoverImage: this.hoverImage,
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    components: {
        
    },
    methods: {
        mouseoverFinish(value) {
            this.localHoverImage = true;
            // this.localSelectedFinish = value;
        },
        mouseoutFinish(value) {
            this.localHoverImage = false;
            // this.localSelectedFinish = 0;
        },
        localUpdateSelectedFinish(value) {
            this.localSelectedFinish = value;
        }
    }
})
