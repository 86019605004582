export const siteName = 'Lark and Larks';
export const contactEmail = 'sales@larkandlarks.co.uk';
export const contactNumber = '0330 135 9090';
export const siteLogo = 'https://www.larkandlarks.co.uk/files/logo-sm.png';
export const siteImage = 'https://www.larkandlarks.co.uk/_nuxt/img/logo.4fd9b5d.svg';
export const siteBaseUrl = 'https://larkandlarks.co.uk';
export const facebookLink = 'https://www.facebook.com/LarkandLarks';
export const instagramLink = 'https://www.instagram.com/larkandlarks/';
export const twitterLink = 'https://x.com/larkandlarks';
export const pinterestLink = 'https://www.pinterest.co.uk/larkandlarks/';
export const houzzLink = 'https://www.houzz.com/hznb/professionals/kitchen-and-bath-fixtures/lark-and-larks-pfvwgb-pf~1452229224';
export const street = '1059-1069 Kingsbury Road';
export const town = 'Tyburn';
export const region = 'Birmingham';
export const postcode = 'B35 6AJ';
export const country = 'GB';
export const daysOpen = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
export const opensAt = '9:00am';
export const closesAt = '4:30pm';

