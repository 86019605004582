import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=b2d6f8ac&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=ts&"
export * from "./Home.vue?vue&type=script&lang=ts&"
import style0 from "./Home.vue?vue&type=style&index=0&id=b2d6f8ac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2d6f8ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SlickCarousel: require('/var/www/components/SlickCarousel.vue').default,BenefitsBar: require('/var/www/components/BenefitsBar.vue').default,ZoomCards: require('/var/www/components/ZoomCards.vue').default,IconBar: require('/var/www/components/IconBar.vue').default,Cards: require('/var/www/components/Cards.vue').default,RangeSlider: require('/var/www/components/RangeSlider.vue').default,HeroBanner: require('/var/www/components/HeroBanner.vue').default,TrustPilot: require('/var/www/components/TrustPilot.vue').default,FullImageCardBanner: require('/var/www/components/FullImageCardBanner.vue').default,VideoCopySplit: require('/var/www/components/VideoCopySplit.vue').default})
